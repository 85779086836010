/**
 * showHideSearch
 **/
var showHideSearch = {
    init : function() {
        jQuery('#mobile-search-trigger, .toggle-search.menu-item').click(function(e) {
            e.preventDefault();
            showHideSearch.showSearch();
        });
        jQuery('#section-site-search-form .input-group-prepend').click(function() {
            showHideSearch.hideSearch();
        });
        showHideSearch.escHideSearch();
	},
    showSearch : function() {
        jQuery('#section-site-search-form').addClass('show');
        jQuery('#section-site-search-form input[type="text"]').focus();
	},
    hideSearch : function() {
        jQuery('#section-site-search-form').removeClass('show');
        jQuery('#section-site-search-form input[type="text"]').val('');
	},
	escHideSearch : function() {
        window.addEventListener("keydown", function (event) {
            if (event.defaultPrevented) {
                return;
            }
            switch (event.key) {
                case "Escape":
                    showHideSearch.hideSearch();
                    break;
                default:
                    return;
            }
            event.preventDefault();
        }, true);
	}
};

jQuery(document).ready(function() {
    showHideSearch.init();
});
