/**
 * site
 **/

// Initilize debugging with 'localStorage.debug = true' in your console
var log = bows('site');

// log('some var we need to check');

var site = {
  init: function() {
    // site.reframe();
    modalJs.open('.trigger-modal', '<iframe src="' + jQuery('.lightbox-video').attr('href') + '"/>');
  },
  reframe: function() {
    // reframe('iframe[allowfullscreen]');
  }
};

var debounceActions = function() {
  // add JS function calls here for resize actions
  var siteWidth = document.body.clientWidth;
  var mobileMenu = document.getElementById('section-mobile-menu');
  if (siteWidth < 768) {
    var logo = document.getElementsByClassName('site-title');
    var triggers = document.getElementById('section-mobile-menu-triggers');
    var logoHeight = logo[0].offsetHeight;
    triggers.style.height = logoHeight + 'px';
  } else {
    mobileMenu.classList.remove("show");
  }
};
var debounceCallable = debounce(debounceActions, 250);

jQuery(document).ready(function() {
  site.init();
  eventSlides.init();
  accordian.init();
  debounceCallable();
});

jQuery(window).resize(function() {
  debounceCallable();
});
