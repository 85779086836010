/**
 * modalJs
 **/
var modalJs = {

	fadeInSpeed : 200,
	fadeOutSpeed : 200,

	open: function(trigger, html, get) {
		jQuery( trigger ).click(function(e) {
			e.preventDefault();

			jQuery('body').append( modalJs.getHtml() );
			if (get) {
				jQuery.get( html, function( data ) {
					jQuery('.modal-content').html( data );
				  });
			} else {
				jQuery('.modal-content').html( html );
			}

			jQuery('#modal').fadeIn( modalJs.fadeInSpeed );
			jQuery('#modal .close').click( modalJs.closeModal );

			modalJs.activateCloseModal();
			modalJs.pauseScrolling();
		});
	},
	getHtml : function() {
		return '<div id="modal"><div class="inside-wrapper"><span class="close"></span><div id="modal-container"><div class="modal-content"></div></div></div></div>';
	},
    activateCloseModal : function() {
		jQuery(document).mousedown(function(e) {
			if ( 'modal' === jQuery(e.target).attr('id') ) {
				modalJs.closeModal();
			} else {
				return;
			}
		});
		jQuery(document).keydown(function(e) {
			switch (e.which) {
				case 27 :
					modalJs.closeModal();
					break;
			}
		});
	},
	closeModal : function() {
		jQuery('#modal').fadeOut( modalJs.fadeOutSpeed, function() {
			modalJs.unPauseScrolling();
			jQuery('#modal').remove();
		});
	},
	pauseScrolling : function() {
		jQuery('body').css({
			'overflow' : 'hidden'
		});
	},
	unPauseScrolling : function() {
		jQuery('body').css({
			'overflow' : 'auto'
		});
	}
};
