/**
 * event slider
 **/

var eventSlides = {
    init: function() {
      var log = bows('event slider');
      this.eventSlides(log);
    },
    eventSlides: function(log) {
      // Check that tiny slider is initialized
      if (typeof tns === 'function') {
        var tnsCarousel = document.querySelectorAll('.event-slider__slider');
        tnsCarousel.forEach(function(eventSet) {
          var slider = tns({
            autoplay: true,
            autoplayButtonOutput: false,
            autoplayTimeout: 5000,
            controls: false,
            prevButton: '.event-slider__prev',
            nextButton: '.event-slider__next',
            container: eventSet,
            gutter: 15,
            items: 1,
            nav: true,
            navPosition: 'bottom',
            speed: 1000,
            preventScrollOnTouch: 'auto',
            responsive: {
              768: {
                controls: true,
              }
            }
            // responsive: {
            //   576: {
            //     gutter: 25,
            //     items: 2,
            //     slideBy: 2
            //   },
            //   992: {
            //     gutter: 13,
            //     items: 3,
            //     slideBy: 3
            //   }
            // }
          });
        });
      }
    }
  };
  