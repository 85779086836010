/**
 * accordian
 **/

var accordian = {
    init: function () {
        this.accordianContentShow();
    },
    accordianContentShow: function () {
        var conAcc = jQuery('.content-accordian');
        conAcc.click(function() {
            jQuery(this).children('.accordian-title').toggleClass('is-open');
            jQuery(this).children('.accordian-content').toggleClass('is-visible');
        });
    }
}